import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import * as prismicH from "@prismicio/helpers";
import moment from 'moment';
import { BlogJsonLd } from 'gatsby-plugin-next-seo';
import { Layout } from '../components/Layout'
import { Pagination } from '../utils/pagination'
import { SliceZone } from '@prismicio/react'
import { components } from '../slices'
import { BlogListItem } from '../components/BlogListItem';

const Blog = ({data, pageContext}) => {

  // const [blogItems,setBlog] = useState([])
  
  // useEffect( ()=> {
  //   const docs = data.allPrismicBlogItem || []
  //   const now = moment()
  //   const filtered = docs.nodes.filter(item => {
  //     console.log('item: ', item)
  //     const data = item.data
  //     // console.log('start: ',data.start_time)
  //     // console.log('finish: ',data.finish_time)
  //     // const startTime = moment(data.start_time)
  //     // const finishTime = moment(data.finish_time)
  //     // return (startTime < now)
  //     return true;
  //   })
  //   console.log('portfolio: ', filtered)
  //   setBlog(filtered)
  // }, [data.allPrismicBlogItem])
  
  if (!data) return null
  //console.log('data: ', data)
  //console.log('context: ', pageContext)

  const docs = data.allPrismicBlogItem

  const blog = data.prismicBlog || {}
  const seo = {
    title: blog.data.seo_title + " page " + docs.pageInfo.currentPage,
    description: blog.data.seo_description + " Page " + docs.pageInfo.currentPage + ".",
    keywords: blog.data.seo_keywords,
  }
  //console.log('seo: ', seo)

  const topMenu = data.prismicTopMenu || {}
  const footer = data.prismicFooter || {}

  
  //console.log('docs: ', docs);

  const blogItems = data.allPrismicBlogItem.nodes;

  console.log('blogItems: ', blogItems);
  const posts = blogItems.map(item => {
    return { headline: item.data.title, image: item.data.cover_img.url }; 
  });

  return (
    <Layout topMenu={topMenu.data} footer={footer.data} seo={seo}>
      <BlogJsonLd
        url={"https://www.yohn.io" + blog.url}
        headline={blog.data.seo_title + " page " + docs.pageInfo.currentPage}
        images={"https://www.yohn.io/meta-image.png"}
        posts={posts}
        datePublished={blogItems[blogItems.length - 1].data.timestamp}
        dateModified={blogItems[0].data.timestamp}
        authorName='Yohn Team'
        description={blog.data.seo_description}
      />
      <div className="bg-light flex-fill" style={{minHeight:"70vh"}}>
        <div className="py-lg-4" >
          <div className="container py-5">
            <h1 className="h2">{blog.data.title}</h1>
            <div className="mt-4 py-4 px-3 px-sm-4 px-lg-5 bg-white">
              {
              blogItems.map((item, index) => (
                <div key={index} className="">
                  <BlogListItem item={item} />
                </div>))
              }
            </div>
            <Pagination pageInfo={docs.pageInfo} base={'blog'}/>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query MyBlogQuery($limit: Int!, $skip: Int!) {
    prismicBlog {
      href
      _previewable
      url
      data {
        seo_title
        seo_description
        seo_keywords
        title
        body {
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
          ...BlogDataBodySeparateHeader
        }
      }
      url
      type
    }

    prismicTopMenu {
      ...TopMenuFragment
    }
    prismicFooter {
      ...FooterFragment
    }

    allPrismicBlogItem(
    sort: { fields: data___timestamp, order: DESC }
    limit: $limit
    skip: $skip
    ) {
    nodes {
      _previewable
      uid
      url
      data {
        #seo_title
        #seo_description
        #seo_keywords
        title
        subtitle
        date
        timestamp
        cover_img {
          alt
          url
        }
        author_name
        author_link {
          url
        }
        author_img {
          url
        }
        #sort
        body {
          ... on PrismicBlogItemDataBodyBlogText {
            id
            slice_type
            primary {
              text {
                richText
              }
            }
          }
        }
      }
    }
    pageInfo {
      currentPage
      pageCount
    }
  }
  }
`

export default withPrismicPreview(Blog)
